// React & Dependencies
import React from 'react';

import { string, shape, bool, arrayOf, oneOfType, object, func } from 'prop-types';
import classNames from 'classnames';

// RecommendationsFE Components
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';

import { RecommendationsCardVertical } from '@recommendations-fe/card';
import { measuringHeight, measuringFlag } from '@polycard/card';
import IconFactory from '../recommendations-icons/icon-factory';
import useRecosBookmarks from '../recommendations-hooks/use-recos-bookmarks';

// Homes Components
import Section from '../section';

import withTracker from '../with-tracker';
import seedCart from './seeds/cart-seed';
import seedLastBuy from './seeds/last-buy-seed';
import itemMapper from '../recommendations-list/json-to-props';
import { slidesPerViewInRecos } from '../../../../utils/slider-per-view';

import { DEVICE_TYPE, CARD_TYPE } from '../../../../utils/constants';

// Homes CustomSeedCards
const seeds = {
  'cart-recommendations': seedCart,
  'last-buy-recommendations': seedLastBuy,
};

const namespace = 'ui-recommendations-snapped';

const RecommendationsFeSnapped = ({
  id,
  seed_info,
  highlight_seeds,
  restClient,
  className,
  country,
  recommendation_data,
  items,
  bookmarks: bookmarkedItemsFromState,
  reco_track,
  is_polycard = false,
  lazy = false,
  deviceType = DEVICE_TYPE.DESKTOP,
  isDualCarousel = false,
  isDualCarouselSingle = false,
  dualRef,
  pagination_config: paginationConfig,
  viewportWidth,
  onResize = null,
  ...rest
}) => {
  const { bookmarkedItems, updateBookmarkCallback } = useRecosBookmarks({
    restClient,
    bookmarkedItemsFromState,
  });

  const prevItems = [];

  const CustomSeedCard = seeds[id];
  if (CustomSeedCard && seed_info) {
    const metadata = is_polycard
      ? { id: seed_info.items.map((item) => item.id).join('-') }
      : {};
    prevItems.push({
      CustomCard: CustomSeedCard,
      ...seed_info,
      metadata,
    });
  }

  const classNamesIndexMap = {};

  if (highlight_seeds) {
    classNamesIndexMap[0 + prevItems.length] = 'highlight-seed';
  }

  let apiresponse = recommendation_data;
  if (items && !reco_track) {
    apiresponse = {
      recommendation_info: {
        recommendations: items.map(itemMapper),
      },
    };
  }

  const measuringFunctions = is_polycard
    ? { measuringHeight, measuringFlag }
    : {};

  return (
    <Section className={`${namespace}-section`} testId={id}>
      <RecommendationsCarouselSnapped
        id={id}
        ref={dualRef}
        IconFactory={IconFactory}
        Card={RecommendationsCardVertical}
        prevItems={prevItems}
        classNamesIndexMap={classNamesIndexMap}
        onBookmarkClick={updateBookmarkCallback}
        bookmarkedItems={bookmarkedItems}
        cardType={CARD_TYPE.GRID}
        isPolycard={is_polycard}
        apiresponse={apiresponse}
        preload={!lazy}
        fetchPriority={!lazy && 'high'}
        criticalImages={
            isDualCarouselSingle
              ? 1
              : slidesPerViewInRecos(isDualCarousel, deviceType)
          }
        lazy={lazy}
        deviceType={deviceType}
        paginationConfig={paginationConfig}
        carouselSpacing={20}
        slidesPerView={
            isDualCarouselSingle
              ? 1
              : slidesPerViewInRecos(isDualCarousel, deviceType)
          }
        onResize={onResize}
        contextProps={{
          thousandSeparator: country?.thousands_separator,
          decimalSeparator: country?.decimal_separator,
        }}
        {...measuringFunctions}
        {...rest}
        className={classNames('new-carousel', className)}
      />
    </Section>
  );
};

RecommendationsFeSnapped.propTypes = {
  restClient: shape({}),
  id: string,
  seed_info: shape({}),
  highlight_seeds: bool,
  className: string,
  country: shape({}),
  recommendation_data: shape({}),
  bookmarks: oneOfType([object, arrayOf(string)]),
  items: arrayOf(shape({})),
  reco_track: shape({}),
  is_polycard: bool,
  lazy: bool,
  isDualCarousel: bool,
  isDualCarouselSingle: bool,
  dualRef: string,
  pagination_config: shape({
    mode: string,
    position: string,
  }),
  deviceType: string,
  viewportWidth: string,
  onResize: func,
};

export default withTracker(RecommendationsFeSnapped);
